import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Navbar,
  NavbarBrand,
  Container,
  Row,
  Col
} from 'reactstrap'
import ShareBar from '../Social/ShareBar';
import { useLocation } from "react-router-dom";
import './Template.scss'

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const nav = (transparentNav, removeNav, isOpen) => {
  if (!removeNav) {
    return (
      <Navbar
        className={`navBackground ${isOpen ? 'open' : 'closed'} ${
          transparentNav ? 'transparent' : ''
          }`}
        dark
        expand="md">
        <Container>
          <NavbarBrand to="/" tag={Link}>
            <img
              src="/img/GV_Logo.png"
              alt="The future we need"
              height="65px"
            />
          </NavbarBrand>
        </Container>
      </Navbar>
    )  
  } else {
    return null
  }
}


const Footer = (props) => {
  const [imprint, setImprint] = useState('...')

  useEffect(() => {
    const getImprint = async () => {
      try {
        const res = await fetch('/imprint')
        const text = await res.text()
        setImprint(text)
      } catch (err) {
        console.log('Error:', err)
      }
    }

    getImprint()
  }, [])

  return (
    <div className="footer">
      <Container fluid>
        <Row style={{borderBottom: "2px solid white"}}>
          <Col>
          <ShareBar />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="py-0">
          <Col>
            <ul className="footer-list mb-0 mt-4">
              <li>
              <Link to="/">
                  <img
                    src="/img/gv_logo.svg"
                    alt="The future we need"
                    height="100px"
                  />
              </Link>
            </li></ul>
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 9 }} sm={{ size: 12 }}>
            <ul className="footer-list">
              <li>
                <div className="d-flex social-btns">
                  <a className="mr-2" href="https://www.facebook.com/labourgrassrootsvoice"><img src="/img/FB_NAV_button.svg" alt="Share on Facebook"/></a>
                  <a href="https://twitter.com/future_we_need"><img src="/img/TWITTER_nav_button.svg" alt="Share on Twitter"/></a>
                </div>
              </li>
              <li><br /></li>

              <li><a href="https://peoplesmomentum.com/privacy-policy/"><strong>PRIVACY POLICY</strong></a></li>
              <li><br /></li>
              <li><strong>Photos:</strong></li>
              <li><strong><a href="https://www.instagram.com/henricalderon/">Henri Calderon</a></strong></li>
              <li><strong>Unsplash</strong></li>

              <li><br /></li>

              <li><strong>COPYRIGHT © MOMENTUM { new Date().getFullYear() }</strong></li>
              <li><br /></li>

              <li><strong>{ imprint }</strong></li>
              <li><br /></li>

            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

function Template({ id, children, transparentNav = false, removeNav = false }) {
  const [isOpen] = useState(false)
  ScrollToTop()
  return (
    <div {...(id && { id })}>
      {nav(transparentNav, removeNav, isOpen)}
      <div className="pageBody">{children}</div>
      <Footer />
    </div>
  )
}
export default Template
