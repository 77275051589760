import React from 'react'
import Template from '../components/Template/Template'
import { Container, Row, Col} from 'reactstrap'

function NotFound({history}) {
  return (
    <Template>
      <Container>
        <Row>
          <Col>
          <h1>404</h1>
          <p>Sorry, this page doesn't exist.</p>
          <a href="/" style={{color: "black"}}>Return home -></a>
          </Col>
        </Row>
      </Container>
    </Template>
  )
}

export default NotFound
