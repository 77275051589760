import React from "react";
import { Row, Col } from 'reactstrap';

const pageToShare = () => {
  const url = new URL(window.location.href)
  if (url.search === "") {
    url.search = "?utm_source=share"
  } else {
    url.search += "&utm_source=share"
  }
  return encodeURIComponent(`${process.env.REACT_APP_BASE_URL}${url.pathname}`)
}

// Adapted from MPTW codebase
function facebookShareUrl() {
  return `https://facebook.com/sharer/sharer.php?u=${pageToShare()}`;
}

const tweet_copy = `The Labour Party has to tackle the failures of the rigged system head-on. It's time to embrace transformative socialist ideas, build a movement, and take on the billionaires.

That's why I'm supporting the @future_we_need candidates for Labour's NEC.`

function twitterShareUrl() {
  return `https://twitter.com/intent/tweet/?text=${encodeURIComponent(tweet_copy)}&url=${pageToShare()}`;
}

// 📣 *Tell us when your virtual Constituency Labour Party (CLP) nomination meeting is.* Nominations are critical to getting our candidates elected, and we need to mobilise our movement to support Grassroots Voice candidates at each and every one. 
const whatsapp_copy = `So much depends on Labour’s upcoming NEC elections. *Strong grassroots voices on the NEC can push Labour to fight for racial justice, tackle the climate crisis and stand with unions against catastrophic job losses.*

We have the numbers to win this election. *And now we have a united left slate to rally behind.* ${process.env.REACT_APP_BASE_URL}

*The Grassroots Voice slate:*

- *Gemma Bolton*
- *Jess Barnard*
- *Mish Rahman*
- *Yasmine Dar*
- Youth rep: *Lara McNeill*
- Disabled rep: *Ellen Morrison*
- BAME rep: *Ankunda Matsiko*
- Welsh rep: *Lynne Jones*

Backed by a wide range of left organisations, the slate is a cross-section of our movement - from grassroots activists to councillors and a former MP. The eight candidates are 50% BAME, 75% women and come from across the country, from Sussex, Manchester, Norwich, Nottingham, to the Midlands and Wales, and they are *embedded in communities where Labour needs to win.*

Here’s how you can get involved in the campaign:

🔥 *Sign up at ${process.env.REACT_APP_BASE_URL} and tell us what positions you're eligible to vote for*

🔥 *Follow the _Grassroots Voice: The Future We Need_ slate on social media*

*Facebook:*
*https://www.facebook.com/labourgrassrootsvoice*

*Twitter:*
*https://twitter.com/future_we_need*

🔥 *Are you under 27 or a student? Check out https://socialistfuture.com/ to find out how to vote in the Young Labour and Labour Students elections and sign up so we can get out the vote!*

*Lastly, please forward this message to other Labour Party members!* If we pull together, we can win this vital election and build a Labour Party that fights for the future we need.`

function whatsAppShareUrl() {
  return `whatsapp://send?text=${encodeURIComponent(whatsapp_copy)}`;
}

const ShareButton = ({url, imgUrl, text}) => {
return (
  <Col className="p-0 m-3" sm={{ size: 12 }} md={{ size: 3 }}>
    <a className="btn-outline-primary btn btn-block type-label-1 mt-2 mr-2"
       href={url} target="_blank" rel="noopener noreferrer">
      <img src={imgUrl} alt={text} className="mr-2"/>
      {text}
    </a>
  </Col>
  )
}



const ShareBar = () => {
  return (
    <div className="share-bar bg-red color-light">
      <Row className="p-0 justify-content-center">

        <ShareButton
          url={facebookShareUrl()}
          imgUrl="/img/fb_icon.svg"
          text="Share on Facebook"
        />

        <ShareButton
          url={twitterShareUrl()}
          imgUrl="/img/twitter_icon.svg"
          text="Share on Twitter"
        />

        <ShareButton
          url={whatsAppShareUrl()}
          imgUrl="/img/Whatsapp_icon.svg"
          text="Share on WhatsApp" />
        </Row>
    </div>
  );
};

export default ShareBar;
