import React from 'react'
import HeaderTags from '../../components/HeaderTags'
import Template from '../../components/Template/Template'
import SimplePage from '../../components/SimplePage/SimplePage'
import ReactMarkdown from 'react-markdown'

const statementOfPrinciples_copy = `
# Our Values

Grassroots Voice stands for intersectionality and working class unity, and for dismantling structural inequalities and confronting all forms of prejudice both within the Labour Party and wider society. 

Mish Rahman, Grassroots Voice candidate:

_If elected to the NEC, we will make clear to the leadership that Black Lives Matter is a movement for lasting change, not a “moment” that can be brushed aside, and we will fight for justice for the abuses revealed in the leaked report, including tackling the systemic racism and sexism the report exposed._

_We will campaign against all forms of racism including antisemitism, Islamophobia and Afrophobia and the scapegoating of migrant and Traveller communities, and against sexism, sexual harassment, homophobia, biphobia, transphobia and ableism._

_We will stand in solidarity with the entire LGBTQ community in proudly demanding their rights, including trans rights. Trans women are women, trans men are men, and non-binary people are non binary. Labour must continue its support for reforming the Gender Recognition Act and stand up to the Tories' attempts to fuel prejudice against the trans community._

_We will work to ensure Labour meets the needs of disabled people at all levels of the Party and we will champion greater representation for all equalities groups within the Party's structures and in candidate selections._`

export default () => {
  return (
    <Template>
      <HeaderTags
        title="What we stand for"
        url="/principles"
        imgUrl="/img/profile.png" 
        description="Our values"
        large={false}
      />

      <SimplePage
        body={<ReactMarkdown source={statementOfPrinciples_copy}/>}
      />
    </Template>
  )
}
