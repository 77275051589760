import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { CandidateExcerptLanding } from '../CandidateExcerpt/CandidateExcerpt'

import CandidateData from '../../candidates';

function LayupCandidates(start, finish) {
  // If we're showing two candidates, give them some more space.
  const mdSize = finish - start === 2 ? 6 : 4
  return CandidateData.slice(start, finish).map(candidate => {
    return (
      <Col className="px-4" key={candidate.slug} md={{ size: mdSize }} sm={{ size: 12 }}>
        <CandidateExcerptLanding
          position={candidate.position} 
          slug={candidate.slug}
          handle={candidate.handle} 
          imageUrl={candidate.imgUrl} 
          name={candidate.name} 
          labourMembershipNumber={candidate.labourMembershipNumber}
          blurb={candidate.blurb} 
          colorClass="color-light" />
      </Col>
    )
  })
}


const CandidateLanding = () => {
  return <div className="bg-dark candidates">
    <Container className="p-0" >
      <Row className="no-gutters">
        <Col>
          <h1>Our NEC candidates:</h1>
        </Col>
      </Row>
      <Row className="p-0 pb-4 no-gutters">
        {LayupCandidates(0, 2)}
      </Row>
      <Row className="p-0 no-gutters">
        {LayupCandidates(2, 4)}
      </Row>
      <hr />
      <Row className="p-0 pb-4 justify-content-center no-gutters">
        {LayupCandidates(4, 9)}
      </Row>
      <hr />
      <Row className="no-gutters">
        <Col>
          <h1>Our NPF candidates:</h1>
        </Col>
      </Row>
      <Row className="p-0 pb-4 justify-content-center no-gutters">
        {LayupCandidates(9, 14)}
      </Row>
      <hr />
      <Row className="p-0 pb-4 justify-content-center no-gutters">
        {LayupCandidates(14, 16)}
      </Row>
      <hr />
      <Row className="p-0 pb-4 justify-content-center no-gutters">
        {LayupCandidates(16, 21)}
      </Row>
      <hr />
      <Row className="p-0 pb-4 justify-content-center no-gutters">
        {LayupCandidates(21, 26)}
      </Row>
      <hr />
      <Row className="p-0 pb-4 justify-content-center no-gutters">
        {LayupCandidates(26, 30)}
      </Row>
      <hr />
      <Row className="p-0 pb-4 justify-content-center no-gutters">
        {LayupCandidates(30, 32)}
      </Row>
      <hr />
      <Row className="p-0 pb-4 justify-content-center no-gutters">
        {LayupCandidates(32, 36)}
      </Row>
      <hr />
      <Row className="p-0 pb-4 justify-content-center no-gutters">
        {LayupCandidates(36, 39)}
      </Row>
      <hr />
      <Row className="p-0 pb-4 justify-content-center no-gutters">
        {LayupCandidates(39, 44)}
      </Row>
      <hr />
      <Row className="p-0 pb-4 justify-content-center no-gutters">
        {LayupCandidates(44, 46)}
      </Row>
      <hr />
      <Row className="p-0 pb-4 justify-content-center no-gutters">
        {LayupCandidates(46, 50)}
      </Row>
    </Container>
  </div>
}

export default CandidateLanding
