import React from 'react'
import { Link } from 'react-router-dom';

function CandidateImage(position, imageUrl, name, colorClass) {
  const hedClasses = "m-0 pt-2 candidateExcerpt_hed " + colorClass;
  const image = <img className="candidateExcerpt_img" src={imageUrl} alt={name} />;

  const positionDisplay = () => {
    if (position) {
      return <p className="mt-3 mb-1"><span className="type-label-1 bg-red color-light p-1">{position}</span></p>
    } else {
      return null
    }
  }

  return (
      <React.Fragment>
      <div className="d-flex candidateExcerpt_imgWrapper">
        {image}
      </div>
      {positionDisplay()}
      <h3 className={hedClasses}>{name}</h3>
    </React.Fragment>
  )
}

function CandidateLink(slug, children) {
  const path = `/candidate/${slug}`
  return (
    <Link to={path}>
      {children}
    </Link>
  )
}

function CandidateExcerpt(position, slug, handle, imageUrl, name, labourMembershipNumber, colorClass) {
  const bodyClasses = "type-label-1 candidateExcerpt_body " + colorClass;
  const image = CandidateImage(position, imageUrl, name, colorClass)

  const twitterLink = (handle) => {
    const twitterUrl = "https://twitter.com/" + handle;
    if (handle) {
      return <React.Fragment>
        <a href={twitterUrl} ><p className="type-body-sm"><strong>@{String(handle)}</strong></p></a>
      </React.Fragment> 
    } else {
      return null
    }
  }

  return (
    <React.Fragment>
      {CandidateLink(slug, image)}
      {twitterLink(handle)}
      <p className="type-body-sm">{labourMembershipNumber}</p>
    </React.Fragment>
  )
}

function CandidateExcerptLanding(props) {
  // takes position, slug, handle, imageUrl, name, blurb, colorClass, bgClass
  const readmore = <h4 className="text-center p-3 m-0">READ MORE</h4>
  return (
    <div className="candidateExcerpt">
      {CandidateExcerpt(props.position, props.slug, props.handle, props.imageUrl, props.name, props.labourMembershipNumber, props.colorClass)}
      <span className="type-body-sm candidateExcerpt_body">{props.blurb}</span>
      {CandidateLink(props.slug, readmore)}
    </div>
  )
}
export {CandidateExcerpt, CandidateExcerptLanding}
