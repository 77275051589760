import React from 'react'
import { hydrate, render } from "react-dom";
import Router from './components/Router'
import './index.scss'

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<Router />, rootElement);
} else {
  render(<Router />, rootElement);
}
