import React from 'react'
import HeaderTags from '../../components/HeaderTags'
import Template from '../../components/Template/Template'
import Candidates from '../../candidates'
import SimplePage from "../../components/SimplePage/SimplePage"

const findCandidate = (slug) => {
  let result;
  result =  Candidates.filter(c => c.slug === slug)
  if (result.length === 1) {
    return result[0]
  } else {
    return false;
  }
}

const shareCardDescription_copy=`
A Labour NEC candidate fighting for democracy & socialism
`

const Candidate = (props, location) => {
  const slug = props.match.params.slug
  const candidateData = findCandidate(slug)
  if (!findCandidate) {
    props.history.push("/404")
  }
  const shareImageUrl = `/img/${candidateData.name.replace(/ /g, '')}Share.png`
  return (
    <Template id="candidate">
      <HeaderTags
        title={`${candidateData.name} for NEC`}
        url={`/candidate/${candidateData.slug}`}
        imgUrl={shareImageUrl} 
        description={shareCardDescription_copy} 
        large={true} />
      <SimplePage
        title={candidateData.name}
        labourMembershipNumber={candidateData.labourMembershipNumber}
        handle={candidateData.handle}
        body={candidateData.body}
        imgUrl={candidateData.imgUrl}
      />
    </Template>
  )
}

export default Candidate
