import React from 'react'
import { Container, Row, Col } from "reactstrap"
import { Link } from "react-router-dom"
import './SimplePage.scss'

const displayImage = (url, title) => {
  if (url) {
    return <img className="img-simplePage" src={url} alt={title} />
  } else {
    return null
  }
} 

const displayTwitter = (handle) => {
  const link = `https://twitter.com/${handle}`
  if (handle) {
    return <p className="handle"><a href={link} target="_blank" rel="noopener noreferrer"><strong>Twitter: @{handle}</strong></a></p>
  } else {
    return null
  }
}

const sizeColsMd = (url) => {
  if (url) {
    return [4,6]
  } else {
    return [1,9]
  }
}

const sizeColsLg = (url) => {
  if (url) {
    return [3, 9]
  } else {
    return [2, 8]
  }
}

const sizeColsXl = (url) => {
  if (url) {
    return [3, 9]
  } else {
    return [2, 8]
  }
}

function SimplePage({imgUrl = false, title,  labourMembershipNumber = false, body, handle}) {
  return (
    <Container className="simplePage pt-5">
      <Row className="justify-content-center">
        <Col sm={{ size: 12 }} md={{ size: sizeColsMd(imgUrl)[0] }} lg={{ size: sizeColsLg(imgUrl)[0] }} xl={{ size: sizeColsXl(imgUrl)[0]}}>
          <div className="d-flex">
            <Link className="backLink" to="/"><img src="/img/back_arrow.svg" alt="Back"/>BACK</Link>
          </div>
          <div className="d-flex">
            {displayImage(imgUrl, title)}
          </div>
        </Col>
        <Col sm={{ size: 12 }} md={{ size: sizeColsMd(imgUrl)[1] }} lg={{ size: sizeColsLg(imgUrl)[1] }} xl={{ size: sizeColsXl(imgUrl)[1] }}>
          {labourMembershipNumber ? (
            <div class="candidateHeaderFlex">
                <h1 class="candidateHeader">{title}</h1>
                <span class="candidateNumber">{labourMembershipNumber}</span>
            </div>
          ) : title ? (
            <h1>{title}</h1>
          ) : null}
          {body}
          {displayTwitter(handle)}
        </Col>
      </Row>
    </Container>
  )
}

export default SimplePage
