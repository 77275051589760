import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const C2ASection = (props) => {
  //  takes wrapperStyles, bgClass, textClass, btnClass, link, btnText, imgUrl, text
  const wrapperClasses = `c2aSection ${props.bgClass} ${props.textClass}`;
  const btnClasses = `btn btn-block type-label-1  mt-2 ${props.textClass} ${props.btnClass}`;
  const displayImg = (imgUrl) => {
    if (imgUrl) {
      return <img src={imgUrl} alt=""/>
    } else {
      return null
    }
  }
  return (
    <div className={wrapperClasses} style={props.wrapperStyles}>
      <Container className="p-4">
        <Row className="p-0 no-gutters">
          <Col sm={{ size: 12 }} md={{ size: 9 }}>
            {props.text}
            <Col className="p-0" sm={{ size: 12 }} md={{ size: 8 }}>
              <a className={btnClasses} href={props.link} target="_blank" rel="noopener noreferrer">{props.btnText}</a>
            </Col>
          </Col>
          <Col  sm={{ size: 12 }} md={{ size: 2, offset: 1 }}>
          <div className="d-grid">
            {displayImg(props.imgUrl)}
          </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default C2ASection
