import React from 'react'
import HeaderTags from '../../components/HeaderTags';
import Template from '../../components/Template/Template'
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import '../Nominate/Nominate.scss'

const preferenceLists = [
  {
    "regionName": "South East, South London and Wales",
    "prefList": ["Gemma Bolton", "Jess Barnard", "Mish Rahman", "Yasmine Dar"], // SE, SLon, N
  },
  {
    "regionName": "Northern, North West, N Ireland and International",
    "prefList": ["Yasmine Dar", "Mish Rahman", "Jess Barnard", "Gemma Bolton"], // N, NW, NI, Int
  },
  {
    "regionName": "Scotland",
    "prefList": ["Yasmine Dar", "Jess Barnard", "Gemma Bolton", "Mish Rahman"], // S
  },
  {
    "regionName": "East Midlands and West Midlands",
    "prefList": ["Mish Rahman", "Gemma Bolton", "Yasmine Dar", "Jess Barnard"], // WM, EM
  },
  {
    "regionName": "North London",
    "prefList": ["Mish Rahman", "Yasmine Dar", "Jess Barnard", "Gemma Bolton"], // NLon
  },
  {
    "regionName": "Eastern and South West",
    "prefList": ["Jess Barnard", "Mish Rahman", "Gemma Bolton", "Yasmine Dar"], // SW, E
  },
  {
    "regionName": "Yorkshire & the Humber",
    "prefList": ["Jess Barnard", "Yasmine Dar", "Mish Rahman", "Gemma Bolton"], // Y
  },
]

function PreferenceOrders() {
  return (
    <Template id="preferenceOrder">
      <HeaderTags
        title="Grassroots Voice: Find Your Voting Order Here"
        url=""
        imgUrl="/img/candidates_2024.png"
        description="Find the ballot order that will secure a #GV4 win in your area"
        large={true} />  
      <Container className="pt-5">

        <Row className="justify-content-center">
          <Col sm={{ size: 12 }}>
            <div className="d-flex">
              <Link className="backLink" to="/preference"><img src="/img/back_arrow.svg" alt="Back" />BACK</Link>
            </div>
          </Col>
        </Row>

        <Row>
          <Col sm="12" md="8" lg="8" className="nominationWizard">
            <h1>Preference Orders</h1>
              <p>Please find below a list of the regions we have allocated preference lists to, and the respective orders we recommend for each.</p>
              {preferenceLists.map(obj => {
                const prefListEl = obj.prefList.map(item => <li>{item}</li>)
                return (
                  <>
                    <h3>{obj.regionName}</h3>
                    <ol>{prefListEl}</ol>
                  </>
                )
              })}            
          </Col>
        </Row>
      
      </Container>

    </Template>
  )
}

export default PreferenceOrders
