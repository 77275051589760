import React from 'react'
import { Helmet } from 'react-helmet'

function HeaderTags({title, url, imgUrl, description, large}) {

  const urlToUse = process.env.REACT_APP_BASE_URL + url
  const imgUrlToUse = process.env.REACT_APP_BASE_URL + imgUrl
  const twitterType = large ? "summary_large_image" : "summary"

  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name="title" content={title} data-react-helmet="true"/>
        <meta name="description" content={description} data-react-helmet="true" />
        <meta property="og:type" content="website" data-react-helmet="true" />
        <meta property="og:description" content={description} data-react-helmet="true" />
        <meta property="og:url" content={urlToUse} data-react-helmet="true"/>
        <meta property="og:title" content={title} data-react-helmet="true"/>
        <meta property="og:image" content={imgUrlToUse} data-react-helmet="true"/>
        <meta property="twitter:card" content={twitterType} data-react-helmet="true" />
        <meta property="twitter:url" content={urlToUse} data-react-helmet="true"/>
        <meta property="twitter:description" content={description} data-react-helmet="true" />
        <meta property="twitter:title" content={title} data-react-helmet="true" />
        <meta property="twitter:image" content={imgUrlToUse} data-react-helmet="true"/>
      </Helmet>
    </React.Fragment>
  )
}

export default HeaderTags
