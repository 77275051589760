import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Landing from '../pages/Landing/Landing'

import NotFound from '../pages/NotFound'
import Candidate from '../pages/Candidate/Candidate'
import Principles from '../pages/Principles/Principles'
import Preference from '../pages/Preference/Preference'
import PreferenceOrders from '../pages/Preference/PreferenceOrders'

const Router = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Landing} />
      <Route exact path="/candidate/:slug" component={Candidate} />
      {process.env.REACT_APP_SHOW_STATEMENT_OF_PRINCIPLES === "true" &&
        <Route exact path="/principles" component={Principles} />
      }
      {process.env.REACT_APP_SHOW_PREFERENCE_TOOL === "true" &&
      <>
        <Route exact path="/preference" component={Preference} />
        <Route exact path="/preferenceorders" component={PreferenceOrders} />
      </>
      }
      <Route component={NotFound} />
    </Switch>
  </BrowserRouter>
)

export default Router
