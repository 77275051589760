import React, { Component } from 'react'
import Template from '../../components/Template/Template'
import HeaderTags from '../../components/HeaderTags';
import ReactMarkdown from 'react-markdown';
import { Container, Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import C2ASection from '../../components/C2ASection'
import '../Nominate/Nominate.scss'

const donateButton_copy = `
## Donate to Grassroots Voice

A Green New Deal, vocal support for Black Lives Matter, a post-pandemic economy for the many: so much is on the line in Labour’s upcoming NEC elections. Contribute to the Grassroots Voice election fund and help fight for the future we need.
`

const votingExplainer_copy = `
Voting for nine CLP seats on Labour's NEC gets under way on 27th August, using Single Transferable Voting. In the past we could cast nine votes of equal value. In this election we have to rank candidates by preference using numbers.

Momentum is backing the Grassroots Voice Four (#GV4) and has produced sequenced lists to guide members in casting their preference votes, depending on the geographical areas where they live.

By voting for the #GV4 candidates in positions 1 through 4, in a particular order depending on your region, you will help minimise the danger of any of the four being unnecessarily knocked out of the election early during the count. [For information on how STV vote counting works, see this video](https://www.youtube.com/watch?v=P38Y4VG1Ibo).
`
class Preference extends Component {
  constructor() {
    super()

    this.state = {
      preferenceOrder: null,
      validationMessage: null
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleNonResident = this.handleNonResident.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(event) {
    const name = event.target.name

    this.setState({
      [name]: event.target.value
    })
  }

  handleNonResident() {
    this.callServer("INT")
  }

  callServer(postcode) {
    fetch(`/preference/${postcode}`)
      .then(async res => {
        const result = await res.json()
        console.log(result)
        if (result.message) {
          this.setState({
            validationMessage: result.message,
            preferenceOrder: null,
          })
        } else if (result.preferenceOrder) {
          this.setState({
            preferenceOrder: result.preferenceOrder,
            validationMessage: result.printRegion,
          })
        }
      })
      .catch(err => console.log('Error:', err))
  }

  handleSubmit(event) {
    event.preventDefault()
    this.callServer(this.state.postcode)
  }

  render() {
    const {
      postcode,
      preferenceOrder,
      validationMessage,
    } = this.state
    return (
      <Template id="nominate">
      <HeaderTags
        title="Grassroots Voice: Find Your Voting Order Here"
        url=""
        imgUrl="/img/candidates_2024.png"
        description="Find the ballot order that will secure a #GV4 win in your area"
        large={true} />  
      <Container className="pt-5">

          <Row className="justify-content-center">
            <Col sm={{ size: 12 }}>
              <div className="d-flex">
                <Link className="backLink" to="/"><img src="/img/back_arrow.svg" alt="Back" />HOMEPAGE</Link>
              </div>
            </Col>
          </Row>

          <Row>
            <Col sm="12" md="6" lg="4">
            <h2>Get your preference order.</h2>
            <p className="mb-0">Enter your postcode here to find the voting preference for your region that will give #GV4 the best chance of success</p>
            </Col>

            <Col sm="12" md="8" lg="8">
              <hr className="my-4" style={{ border: "thin solid black" }} />

              {preferenceOrder ? (
                <>
                  <p>Here's the Grassroots Voice ballot for <strong>{validationMessage}</strong>.</p>
                  <p><strong>Please fill your ballot in as follows:</strong></p>
                  <ol>
                    {preferenceOrder.map((candidate, index) => <li key={index}>{candidate}</li>)}
                  </ol>
                  <hr className="my-4" style={{ border: "thin solid black" }} />
                  <p><Link className="prefLink" to="/preferenceorders">Click here to see all 4 regional preference lists</Link></p>
                </>
              ) : (
                <>
                  <fieldset className="nomination-meeting-fieldset mt-4">
                    <form onSubmit={this.handleSubmit}>
                      <input
                        className="form-control"
                        type="text"
                        name="postcode"
                        value={postcode}
                        onChange={this.handleChange}
                        placeholder="Postcode"
                        autoFocus
                      />
                      <Button className="btn type-label-1">GET YOUR PREFERENCE ORDER</Button>
                      <p className="mt-4">{validationMessage}</p>
                      <p className="mb-0">
                        <Link to="#" className="prefLink" onClick={this.handleNonResident}>
                          I'm not a resident of the UK
                        </Link>
                      </p>
                    </form>
                    <hr className="my-4" style={{ border: "thin solid black" }} />

                    <ReactMarkdown
                      renderers={{
                        paragraph: (props) => <p className="type-body-1">{props.children}</p>,
                        link: (props) => <a style={{ color: "#FF004D"}} href={props.href}>{props.children}</a>
                      }}
                      source={votingExplainer_copy}
                    />
                    <Link to="/preferenceorders" className="prefLink">
                      See all the regional lists we're recommending here.
                    </Link>
                  </fieldset>
                </>
              )}
            </Col>
          </Row>

        </Container>

        {preferenceOrder ? (
          <Container>
            <Row>
              <Col xs={{ size: 12 }} md={{ size: 4 }}></Col>
              <Col xs={{ size: 12 }} md={{ size: 8 }}>
                <h2>
                  <span style={{ background: "yellow", padding: "2px" }}>Donate to help us</span>
                  <br />
                  <span style={{ background: "yellow", padding: "2px" }}>reach more voters</span>
                </h2>
                <p>Our targeted Facebook adverts have already reached 80,000 Labour members. Help us reach even more by donating today.</p>
                <a className="btn btn-outline-primary bg-red btn-dark-on-hover type-label-1 my-2"
                  href="https://momentum.nationbuilder.com/nec_fund" target="_blank" rel="noopener noreferrer" style={{ maxWidth: "250px", width: "100%" }}>Donate</a>
              </Col>
            </Row>
          </Container>
        ) : (<></>) }

      </Template>
    )
  }
}

export default Preference
