import React, { useState } from 'react';
import { Collapse, Button, Container,Row, Col } from 'reactstrap';

const Accordion = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const buttonText = (isOpen) => {
    if (isOpen) {
      return props.openButtonText
    } else {
      return props.closedButtonText
    }
  }

  return (
    <div>
      <div className="d-flex justify-content-center">
      {props.standfirst}
      <Button color="red" onClick={toggle} className="btn btn-red btn-outline-primary type-label-1 d-inline outside-btn">{buttonText(isOpen)}</Button>
      </div>    

      <Collapse className="mt-4" isOpen={isOpen}>
        <Container fluid >
          <Row className="p-0">
            <Col className="p-0">
            {props.body}
            <Button color="red" onClick={toggle} className="btn btn-block btn-red btn-outline-primary type-label-1 d-inline inside-btn mt-3" style={{width: '100%'}}>{buttonText(isOpen)}</Button>
            </Col>
          </Row>  
        </Container>
      </Collapse>
    </div>
  );
}

export default Accordion;
