import React from 'react'
import { Button, FormGroup } from 'reactstrap'
import { Formik, Field } from 'formik'
import Iframe from 'react-iframe';

// should be 0 in prod!
const DEBUG_STARTING_PAGE = 0;

const required = value => (value ? undefined : 'Required')

const Error = ({ name }) => (
  <Field name={name}>  
    {({ form: { touched, errors } }) =>
      touched[name] && errors[name] ? (
        <span className="inputError">{errors[name]}</span>
      ) : null
    }
  </Field>
)

// helper to generate consent object for signups
const generateConsents = (generalConsent) => ({
  general: generalConsent ? {
    reason: 'Opted in NEC2024 site signup',
    email: true,
    call: true,
    text: true,
    post: true,
    social: true
  } : undefined,
  "NEC2024 Signup": {
    reason: 'implicit',
    email: true,
    call: true,
    text: true,
    post: true,
    social: true
  },
})

class Wizard extends React.Component {
  static Page = ({ children, parentState }) => {
    return children(parentState)
  }

  constructor(props) {
    super(props)
    this.state = {
      page: DEBUG_STARTING_PAGE,
      values: props.initialValues,
      submitted: false
    }
  }

  next = values =>
    this.setState(state => ({
      page: Math.min(state.page + 1, this.props.children.length - 1),
      values
    }))

  validate = values => {
    const activePage = React.Children.toArray(this.props.children)[
      this.state.page
    ]
    return activePage.props.validate ? activePage.props.validate(values) : {}
  }

  handleSubmit = (values, bag) => {
    const { onFirstSubmit, onSecondSubmit } = this.props
    const { page } = this.state
    this.props.pageFunc()
    switch (page) {
      case 0:
        this.next(values)
        bag.setSubmitting(false)
        break;
      case 1:
        this.next(values);
        onFirstSubmit(values, bag)
        break;
      case 2:
        this.setState({ submitted: true })
        return onSecondSubmit(values, bag)
      default:
        break;  
    }
  }

  render() {
    const { children } = this.props
    const { page, values, submitted } = this.state
    const activePage = React.Children.toArray(children)[page]
    let buttonText;
    if (page === 1 || page === 2) {
      buttonText = "SUBMIT";
    } else {
      buttonText = "SIGN UP";
    }
    const formClasses = () => {
      let wizPage = `wizardPage_${page}`
      let result = `email-signup formParent ${wizPage}`
      if (page === 0) {
        result += " d-flex"
      }
      return result
    }
    return (
      <Formik
        initialValues={values}
        enableReinitialize={false}
        validate={this.validate}
        onSubmit={this.handleSubmit}
        page={this.state.page}>
        {({status, ...props}) => (
          <form className={formClasses(page)} onSubmit={props.handleSubmit}>
            {!status?.error ? (
              <>
                {React.cloneElement(activePage, { parentState: { ...props } })}
                {(page === 0) && (
                  <FormGroup className="heroForm">
                    <Button className="btn-block btn-c2a initial-btn" type="submit">
                      {buttonText}
                    </Button>
                  </FormGroup>
                )}
                {(page === 1 && !submitted) && (
                  <FormGroup>
                    <FormGroup>
                      <Button
                        className="btn-block btn-c2a"
                        type="submit"
                        disabled={props.isSubmitting}>
                        {buttonText}
                      </Button>
                    </FormGroup>
                  </FormGroup>
                )}
                {(page === 2) && (
                  <FormGroup className="heroForm">
                    <Button className="btn-block btn-c2a" type="submit">
                      {buttonText}
                    </Button>
                  </FormGroup>
                )}
                {submitted && (
                  <FormGroup>
                    <p>Thank you. We will be in touch soon.</p>
                  </FormGroup>
                )}
              </>
            ) : (
              <FormGroup>
                <p>Something went wrong. Please refresh and try again.</p>
              </FormGroup>
            )}
          </form>
        )}
      </Formik>
    )
  }
}

class Form extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      page: DEBUG_STARTING_PAGE
    }
    this.setPage = this.setPage.bind(this);
  }

  setPage = (pageNum) => {
    this.setState({ page: this.state.page + 1 });
  };

  formBookendCopy = (wizardPage) => {
    switch (wizardPage) {
      case 0:
        return <React.Fragment>
          <h2 className="hide-mobile">A united left on Labour's NEC and NPF</h2>
          <p> Ready to go? Join the campaign.</p>
        </React.Fragment>
      case 3:
        return <React.Fragment>
          <h2 className="hide-mobile">A united left on Labour's NEC and NPF</h2>
          <p>Thanks for signing up, we will be in touch soon.</p>
        </React.Fragment>
      default:
        break;
    }
    if (wizardPage < 1 || wizardPage > 2) {
    }
  }
  
  doSignup = (body, actions) => {
    const url = process.env.REACT_APP_SIGNUP_URL
    const options =  {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    fetch(url, options)
    .then(async res => {
      if (!res.ok) throw `Status ${res.status}: ${res.statusText}`
    })
    .then(() => {
      actions.setSubmitting(false)
    })
    .catch(err => {
      console.log('Error:', err)
      actions.setStatus({ error: true })
    })
  }

  render() {
    if (this.state.page < 3) {
      return (
        <React.Fragment>
          <Iframe
            src="https://player.vimeo.com/video/1001232577?h=30d420b648"
            width="640"
            height="640"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
            className="mb-3"
        />
          {this.formBookendCopy(this.state.page)}
          <Wizard
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              phone: '',
              postcode: '',
              labour: true,
              disabled: false,
              youth: false,
              bame: false,
              consent: false,
            }}
            onFirstSubmit={(values, actions) => {
              console.log(values)
              const body = { signup: {
                email: values.email,
                givenName: values.firstName,
                familyName: values.lastName,
                mobile: values.phone,
                postcode: values.postcode,
                consents: generateConsents(values.consent),
                tags: ['NEC2024 Signup'],
              }}
              this.doSignup(body, actions);
            }}
            onSecondSubmit={(values, actions) => {
              console.log(values)
              const body = { signup: {
                email: values.email,
                labour: values.labour,
                bame: values.bame,
                disabled: values.disabled,
                youth: values.youth,
                consents: generateConsents(values.consent),
              }}
              this.doSignup(body, actions);
            }}
            pageFunc={this.setPage}
          >
            <Wizard.Page
              validate={values => {
                const errors = {}
                if (!values.email) {
                  errors.email = 'Required'
                }
                return errors
              }}>
              {_props => (
                <React.Fragment>
                  <FormGroup className="heroForm emailInput">
                    <Field
                      name="email"
                      component="input"
                      type="email"
                      autoComplete="email"
                      placeholder="Email*"
                      validate={required}
                      className="form-control"
                    />
                    <Error name="email" />
                  </FormGroup>
                </React.Fragment>
              )}
            </Wizard.Page>
            <Wizard.Page
              validate={values => {
                const errors = {}
                if (!values.firstName) {
                  errors.firstName = 'Required'
                }
                return errors
              }}>
              {_props => (
                <React.Fragment>
                  <FormGroup>
                    <Field
                      name="firstName"
                      component="input"
                      type="text"
                      autoComplete="given-name"
                      placeholder="First Name*"
                      validate={required}
                      className="w-100 form-control"
                    />
                    <Error name="firstName" />
                  </FormGroup>
                  <FormGroup>
                    <Field
                      name="lastName"
                      component="input"
                      type="text"
                      autoComplete="family-name"
                      placeholder="Last Name"
                      className="w-100 form-control"
                    />
                    <Error name="lastName" />
                  </FormGroup>
                  <FormGroup>
                    <Field
                      name="phone"
                      component="input"
                      type="tel"
                      autoComplete="tel"
                      placeholder="Phone"
                      className="w-100 form-control"
                    />
                    <Error name="phone" />
                  </FormGroup>
                  <FormGroup>
                    <Field
                      name="postcode"
                      component="input"
                      type="text"
                      autoComplete="postal-code"
                      placeholder="Postcode"
                      className="w-100 form-control"
                    />
                    <Error name="postcode" />
                  </FormGroup>
                  <FormGroup className="form-check" style={{ paddingLeft: 0 }}>
                    <label style={{ display: 'flex', fontSize: '14px', marginBottom: 0 }}>
                      <div>
                        <Field
                          className="mr-1"
                          name="consent"
                          component="input"
                          type="checkbox"
                        />
                      </div>
                      <div>
                        I agree to Momentum using the information I provide to keep me updated via mail, email, telephone, text and social media about Momentum’s campaigns and opportunities to get involved, both locally and on a national level. For more information please see our{' '}
                        <a href="https://peoplesmomentum.com/privacy-policy" style={{color: '#eaff00'}}>privacy policy</a>
                        .
                      </div>
                    </label>
                  </FormGroup>
                </React.Fragment>
              )}
            </Wizard.Page>
            <Wizard.Page>
              {_props => (
                <div className="form-checkbox-page">
                  <p>Are you eligible to vote in the following categories?</p>
                  <p>If so, tick the relevant boxes and hit Submit.</p>
                  {checkbox("labour", "Any rep: I am a member of the Labour party")}
                  {checkbox("youth", "Youth rep: I was born after 17th June 1995")}
                  {checkbox("bame", "BAME rep: I am an ethnic minority")}
                  {checkbox("disabled", "Disabled members' rep: I am disabled")}
                </div>
              )}
            </Wizard.Page>
          </Wizard>
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          {this.formBookendCopy(this.state.page)}
        </React.Fragment>
      )
    }
  }
}

const checkbox = (name, label) => {
  return <FormGroup className="form-check">
    <label>
      <Field
        className="mr-1"
        name={name}
        component="input"
        type="checkbox"
      />
      {label}
    </label>
  </FormGroup>
}

export default Form
